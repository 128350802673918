<template>
    <div class="mb-2">
        <div class="row match-height pl-05 pr-05">
            <div :class="'p-05 col-12 col-sm-6 col-md-4' + (service_settings.max_column_count === 4 ? ' col-lg-3' : '')" v-for="(resource, index) in resources" :key="infiniteId+index">
                <resources-infinite-scroll-card :resource="resource" :service_settings="service_settings" />
            </div>
        </div>
        <infinite-loading :identifier="infiniteId" @distance="1" @infinite="infiniteHandler">
            <div slot="no-more"></div>
            <div slot="no-results">{{ __('resource.lms.no_courses_yet') }}</div>
        </infinite-loading>
    </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import Search from '../Search'

export default {
    name: "LMSInfiniteScroll",
    components: {InfiniteLoading, Search},
    props: ['service_settings', 'model_type'],
    data() {
        return {
            resources: [],
            page: 1,
            infiniteId: +new Date(),
        }
    },
    methods: {
        infiniteHandler($state) {
            let vm = this;
            axios
                .get(`/member/${this.model_type}/my-lms?page=${vm.page}`)
                .then(response => {
                    if (response.data.data.length > 0) {
                        $.each(response.data.data, function (key, value) {
                            vm.resources.push(value);
                        });
                        $state.loaded();
                    } else {
                        $state.complete();
                    }
                });
            vm.page++;
        },
    },
}
</script>
