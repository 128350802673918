<template>
    <div>
        <div class="card w-100">
            <iframe :src="course_link" width="100%" height="450px" frameborder="0" allowfullscreen v-if="data.lms_status !== null"></iframe>
            <div class="shadow" v-else-if="data.cover !== null">
                <img class="card-img-top img-fluid" :src="data.cover.url" style="object-fit: cover;" />
            </div>
            <div class="card-content pt-1">
                <div class="col-md-12">
                    <div class="d-flex">
                        <div class="w-100">
                            <div v-if="data.cover === null">
                                <div class="d-inline-block mr-05 mb-05 float-left" v-if="data.resource_file_type === 'doc'">
                                    <img src="/images/icons/doc.png" width="25" />
                                </div>
                                <div class="d-inline-block mr-05 mb-05 float-left" v-if="data.resource_file_type === 'docx'">
                                    <img src="/images/icons/doc.png" width="25" />
                                </div>
                                <div class="d-inline-block mr-05 mb-05 float-left" v-if="data.resource_file_type === 'txt'">
                                    <img src="/images/icons/doc.png" width="25" />
                                </div>
                                <div class="d-inline-block mr-05 mb-05 float-left" v-if="data.resource_file_type === 'pdf'">
                                    <img src="/images/icons/pdf.png" width="25" />
                                </div>
                                <div class="d-inline-block mr-05 mb-05 float-left" v-if="data.resource_file_type === 'xls'">
                                    <img src="/images/icons/xls.png" width="25" />
                                </div>
                                <div class="d-inline-block mr-05 mb-05 float-left" v-if="data.resource_file_type === 'xlsx'">
                                    <img src="/images/icons/xls.png" width="25" />
                                </div>
                                <div class="d-inline-block mr-05 mb-05 float-left" v-if="data.resource_file_type === 'ppt'">
                                    <img src="/images/icons/ppt.png" width="25" />
                                </div>
                                <div class="d-inline-block mr-05 mb-05 float-left" v-if="data.resource_file_type === 'pptx'">
                                    <img src="/images/icons/ppt.png" width="25" />
                                </div>
                                <div class="d-inline-block mr-05 mb-05 float-left" v-if="data.resource_file_type === 'lms' && data.lms_status === null">
                                    <img src="/images/icons/lms.png" width="25" />
                                </div>
                            </div>
                            <h1 class="font-large-1">{{ data.title }}</h1>
                            <div class="mt-05 mb-05" v-if="data.average_rating !== 0 && data.average_rating !== null">
                                <span :class="'rating-static rating-'+data.average_rating * 10">  </span>
                            </div>
                        </div>

                        <div class="ml-auto">

                            <button class="btn-sm btn-outline-warning resource-rating" @click.prevent="showRatings()"
                                    style="min-width: 80px">
                            <span v-if="data.ratings.length === 0">
                                {{ __('resource.form.buttons.add_rating') }}
                            </span>
                                <span v-if="data.ratings.length === 1">
                                {{ __('resource.form.buttons.view_rating') }}
                            </span>
                            </button>
                        </div>

                    </div>
                    <div>
                        <div class="breakAll pt-1">
                            <span class="breakAll image-resize" v-html="data.resource_description"></span>
                        </div>
                    </div>
                    <div class="p-1" v-if="data.disabled === 1">
                        <div class="card bg-outline-gray">
                            <div class="card-header">
                                <h4 class="card-title danger"> {{ __('globals.disabled_resource_box_header') }}</h4>
                            </div>
                            <div class="card-body">{{ __('globals.disabled_resource_box_content') }}</div>
                        </div>
                    </div>
                    <div>
                        <div class="pb-1 pt-05">
                            <div v-if="data.can_be_downloaded === false && data.price !== null ">
                                <strong> {{ __('globals.form.paragraph.your_price') }} {{ data.price | currency(site_tax) }}
                                </strong>
                            </div>
                            <span class="font-small-3 text-bold-700 text-secondary resource-date"><i class="feather icon-calendar"></i> {{ formatDate(data.published_at) }}</span>
                        </div>
                    </div>
                </div>
                <div>
                    <div :class="'d-flex border-top align-items-center p-1 ' + (data.resource_file_type === 'lms' && data.lms_status !== null ? '' : 'border-bottom' )">
                        <newsfeed-owner-info
                            :user_data="data.owner"
                            :supplier_data="null"
                            :shared="false"
                            type="resource"
                            :time="null">
                        </newsfeed-owner-info>

                        <div class="ml-auto">
                            <button class="btn btn-sm btn-outline-danger" @click.prevent="report">
                                {{ __('globals.report') }}
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <div :class="(data.resource_file_type === 'lms' && data.lms_status !== null ? '' : 'p-1' )" v-if="user.confirmed">
                            <div v-if="data.price === null && this.lowest_plan_price !== null && data.can_be_downloaded === false">
                                <strong>
                                    <span>
                                        <a href="/member/plans" style="text-decoration: underline" class="text-dark">{{ __('globals.upgrade') }}</a>
                                        {{ __('globals.to_get_it_for') }} {{ this.lowest_plan_price | currency(site_tax) }} {{ __('globals.on_a') }}
                                        {{ this.lowest_plan_name }} {{ __('globals.plan') }}
                                    </span>
                                </strong>
                            </div>
                            <span class="border-bottom" v-else-if="data.can_be_downloaded">
                                <template v-if="data.resource_file_type === 'lms' && data.lms_status === null">
                                    <button @click.prevent="startCourse()" type="button" class="btn btn-primary waves-effect waves-light form-control">
                                        {{ __('resource.lms.start_course') }}
                                    </button>
                                </template>
                                <template v-if="data.resource_file_type !== 'lms'">
                                    <a v-if="data.external_url !== null" :href="'/member/' + data.type + '/'+data.slug+'/access-external-link'" target="_blank" class="download-button btn btn-primary waves-effect waves-light form-control">
                                        <i class="fa fa-link"></i> {{__('resource.form.buttons.access_external_resource') }}
                                    </a>
                                    <a v-else :href="'/member/' + data.type + '/'+data.slug+'/download-file'" class="download-button btn btn-primary waves-effect waves-light form-control">
                                        <i class="fa fa-download"></i> {{ __('resource.form.buttons.download') }}
                                    </a>
                                </template>
                            </span>
                            <span class="border-bottom" v-if="data.price > 0 && data.can_be_downloaded === false">
                                <a :href="'/member/' + data.type + '/'+data.slug+'/add_to_cart'" class="download-button btn btn-primary waves-effect waves-light form-control">
                                    <i class="fa fa-download"></i> {{ __('resource.form.buttons.buy') }}
                                </a>
                            </span>
                    </div>
                </div>
                <div v-if="data.group != null || data.supplier != null" class="pl-1 pr-1 pt-05 pb-05">
                        <div v-if="data.group != null">
                                <a :href="'/member/group/'+data.group.slug">
                                    <div class="card text-white mb-0 border-0">
                                        <img class="card-img img-fluid" style="height: 3rem;" :src="data.group.group_cover == null ? '/images/cover.jpg' : data.group.group_cover.url">
                                        <div class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                            <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                                    {{ data.group.title }}</p>
                                            <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_group') }} <i class="feather icon-chevron-right"></i></button>
                                        </div>
                                    </div>
                                </a>
                        </div>
                        <div v-if="data.supplier != null">
                                <a :href="'/member/supplier/'+data.supplier.slug">
                                    <div class="card text-white mb-0 border-0">
                                        <img class="card-img img-fluid" style="height: 3rem;" :src="data.supplier.supplier_cover_image == null ? '/images/cover.jpg' : data.supplier.supplier_cover_image.url">
                                        <div class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                            <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                                {{ data.supplier.title }}</p>
                                            <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_page') }} <i class="feather icon-chevron-right"></i></button>
                                        </div>
                                    </div>
                                </a>
                        </div>
                </div>
            </div>
            <div class="d-flex justify-content-between border-top">
                 <div class="font-small-3 text-bold-700">
                        <likes :id="this.data.slug" :likes-count="this.data.likesCount" :is-liked="this.data.isLiked" :liked-by-users="this.data.userLikes" type="resource"></likes>
                </div>
                    <div class="font-small-3 text-bold-700 comment-button">
                            <a class="p-05 d-block" @click.prevent="commentsShow = !commentsShow"><i
                               class="fa fa-comment-o"></i>
                                <span v-if="commentsCount === 1"><span v-text="commentsCount"></span> {{ __('globals.comment') }}</span>
                                <span v-else><span v-text="commentsCount === 0 ? ' ': commentsCount"></span> {{ __('globals.comments') }}</span>
                            </a>
                    </div>
                    <div class="font-small-3 text-bold-700">
                        <social-share :id="this.data.id" :social="this.data.socialShare" @shareModal="triggerShareModal"></social-share>
                    </div>
            </div>
        </div>

         <div v-if="commentsShow">
            <comments :newsfeed_uuid="data.newsfeed_uuid" :id="data.slug" type="resource" @length="updateCommentsCount"></comments>
        </div>
        <div v-if="share_newsfeed">
            <share-modal :original_newsfeed_id="data.newsfeed_id" :module="data" type="resource" @closeModal="share_newsfeed = false"></share-modal>
        </div>

         <ratings
            :data="data"
            module="resource"
            :slug="data.slug"
            :count="data.ratings.length"
            :enableRating="true"
        ></ratings>
    </div>


</template>

<script>
import linkify from 'vue-linkify'
import Share from '../../mixins/newsfeed/Share'
import Resource from "../../mixins/newsfeed/Resource";

import Likes from "../newsfeed/components/Likes.vue";
import Comments from "../newsfeed/components/Comments.vue";
import ShareModal from "../newsfeed/components/ShareModal.vue";

Vue.directive('linkified', linkify)

export default {
    name: "Resource",
    props: ['data', 'course_link'],
    components: {Likes, Comments, ShareModal},
    data() {
        return {
            commentsShow: false,
            commentsCount: this.data.commentsCount,
            share_newsfeed: false,
            submitting: false,
        }
    },
    mixins: [
        Share, Resource
    ],
    beforeMount() {
        this.planPrices(this.data.resource_plan_prices);
    },
    methods: {
        showRatings() {
            $('#rating').toggle();
        },
        updateCommentsCount(count){
            this.commentsCount = count;
        },
        triggerShareModal(){
            this.share_newsfeed = !this.share_newsfeed;
        },
        startCourse(){
            if(this.submitting){
                return false;
            }
            this.submitting = true;
            axios
                .post(`/member/${this.data.type}/${this.data.slug}/user-lms-registration`)
                .then((response) => {
                    let message = {
                        'message': response.data.message,
                        'level': 'success'
                    }
                    flash(message);
                    location.reload();
                })
                .catch((error) => {
                    let message = {
                        'message': 'An Error Occurred',
                        'level': 'danger'
                    }
                    flash(message);
                })
        },
    },
    computed: {
    },
    mounted() {
        this.average_rating();
    }
}
</script>

<style scoped>

</style>
