<template>
    <div class="scrollable-panel">
            <div class="row match-height">
                <div class="col-lg-12 col-md-12 col-sm-12" v-for="(company, index) in companies" :key="index">
                    <organisation-company-directory-card
                        :company="company"
                        :organisationId="organisationId"
                        :index="index">

                    </organisation-company-directory-card>
                </div>
            </div>
            <infinite-loading spinner="spiral" :identifier="infiniteId" @distance="1" @infinite="infiniteHandler">
                <div slot="no-more"></div>
                <div slot="no-results"></div>
            </infinite-loading>
    </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import CompanyUserDirectoryCard from './CompanyUserDirectoryCard.vue';
import OrganisationCompanyDirectoryCard from "./OrganisationCompanyDirectoryCard.vue";

export default {
    name: "OrganisationCompanyList",
    props: {
        organisationId: {
            type: Number,
            required: true,
            validator: function (value) {
                return Number.isInteger(value);
            }
        }
    },
    components: {InfiniteLoading, OrganisationCompanyDirectoryCard},
    data() {
        return {
            companies: [],
            page: 1,
            infiniteId: +new Date(),
            isTyping: false,
            isLoading: false,
        }
    },
    methods: {
        infiniteHandler($state) {
            this.loadCompanies($state)
        },
        loadCompanies($state) {
            let vm = this;
            let url = `/admin/organisation-management/assigned/${this.organisationId}?page=` + this.page;
            axios
                .post(url, {keyword: this.keyword})
                .then(response => {
                    if (response.data.data.length > 0) {
                        $.each(response.data.data, function (key, value) {
                            vm.companies.push(value);
                        });
                        $state.loaded();
                    } else {
                        $state.complete();
                    }
                });
            vm.page++;
        },
    },

}
</script>

<style scoped>
.scrollable-panel {
    max-height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
}

.scrollable-panel::-webkit-scrollbar {
    width: 5px; /* Adjust the width of the scrollbar */
}

.scrollable-panel::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2); /* Color of the scrollbar thumb */
}
</style>
