<template>
    <div class="card">
        <div class="d-flex flex-row align-items-center">
            <div class="d-flex justify-content-start">
                <div class="p-1">
                    <img class="round"
                         :src="user.avatar !== null ? user.avatar.url :  '/images/avatar.png'"
                         alt="avatar" height="30" width="30"/>
                </div>

                <div class="pl-0 pt-1 pr-1 pb-1">
                    <a style="color:inherit;" class="text-bold-600">
                        {{ user.first_name }} {{ user.last_name }}
                    </a>

                    <span v-for="badge in user.badges">
                        <img :src="badge.image" style="margin-top: -3px;  height: 15px;" data-toggle="tooltip"
                             data-placement="bottom"
                             :title="badge.name" alt="badge image"/>
                    </span>

                    <img :src="user.user_badge_image.url" style="margin-top: -3px;  height: 15px;"
                         data-toggle="tooltip"
                         data-placement="bottom"
                         :title="user.user_badge_name"
                         v-if="user.user_badge_name !== null && user.user_badge_icon !== null"
                         alt="badge image"/>

                    <div class="font-small-2 text-muted">{{ user.position }}
                        {{ user.organisation !== null ? 'at ' + user.organisation : '' }}
                    </div>
                </div>
            </div>

            <div class="d-flex p-1 ml-auto">
                <div class="ml-1" v-if="this.planId > 0">
                    <button v-if="isAdminButtonVisible" class="btn waves-effect waves-light btn-sm"
                            :class="{'btn-success': !isAdminOfOrganisation, 'btn-danger': isAdminOfOrganisation}"
                            @click.prevent="toggleOrganisationAdminAssignment">
                        {{ assignmentAdminButtonText }}
                    </button>
                    <button v-if="isLocalOrganisationAdmin" class="btn btn-danger btn-sm" @click.prevent="removeAdminOfOrganisation">
                        Remove Admin
                    </button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: "OrganisationCompanyDirectoryCard",
    props: {
        user: Object,
        index: Number,
        organisationId: {
            type: Number,
            required: true,
            validator: function (value) {
                return Number.isInteger(value);
            }
        },
        planId: {
            type: Number,
            required: false,
            validator: function (value) {
                return value === null || Number.isInteger(value);
            }
        },
        organisationAdmin: {
            type: Number,
            required: false,
            validator: function (value) {
                return value === null || Number.isInteger(value);
            }
        }
    },
    data() {
        return {
            user_id: window.App.user.id,
            localOrganisationAdmin: this.organisationAdmin,
        }
    },
    watch: {
        organisationAdmin(newVal) {
            this.localOrganisationAdmin = newVal;
        },
    },
    computed: {
        assignmentAdminButtonText() {
            return this.isAssignedToCompany && this.organisationAdmin === this.user.id ? "Remove Admin" : "Make Admin";
        },
        isAdminOfOrganisation() {
            return this.user.id === this.organisationAdmin
        },
        isLocalOrganisationAdmin() {
            return this.localOrganisationAdmin === this.user.id;
        },
        isAdminButtonVisible() {
            return !this.isLocalOrganisationAdmin && (this.organisationAdmin === 0 || this.organisationAdmin === this.user.id);
        }
    },
    methods: {
        toggleOrganisationAdminAssignment() {
            if (this.isAdminOfOrganisation) {
                this.removeAdminOfOrganisation();
            } else {
                this.addAdminToOrganisation();
            }
        },
        addAdminToOrganisation() {
            axios
                .put(`/admin/organisation-management/${this.user.username}/add-admin-to-organisation/${this.organisationId}`)
                .then(response => {
                    this.flashResponse(response.data.message, 'success');
                    this.$emit('updateOrganisationAdmin', this.user.id);

                    window.dispatchEvent(new CustomEvent('admin-assigned', {
                        detail: {
                            userId: this.user.id,
                            organisationId: this.organisationId
                        }
                    }));
                })
                .catch((error) => {
                    this.flashResponse('An error occurred', 'danger')
                });
        },
        removeAdminOfOrganisation() {
            axios
                .put(`/admin/organisation-management/${this.user.username}/remove-admin-from-organisation/${this.organisationId}`)
                .then(response => {
                    this.flashResponse(response.data.message, 'success')
                    this.$emit('updateOrganisationAdmin', 0);

                    window.dispatchEvent(new CustomEvent('admin-removed', {
                        detail: {
                            userId: this.user.id,
                            organisationId: this.organisationId
                        }
                    }));
                })
                .catch((error) => {
                    this.flashResponse('An error occurred', 'danger')
                });
        },
        flashResponse(message, status) {
            let flash_data = {
                'message': message,
                'level': status
            }
            flash(flash_data);
        }
    }
}

</script>

<style scoped>

</style>
