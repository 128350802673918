<template>
    <div>
        <div class="shadow" v-if="this.data.cover !== null">
            <img class="img-fluid" :src="this.data.cover.url" style="object-fit: cover;">
        </div>
        <div class="card-content pt-1 pb-1">
            <div class="col-md-12">
                <div class="d-flex">
                    <div class="w-100">
                        <div v-if="this.data.cover === null">
                            <div class="d-inline-block mr-05 mb-05 float-left" v-if="this.data.resource_file_type === 'doc'">
                                <img src="/images/icons/doc.png" width="25px" />
                            </div>
                            <div class="d-inline-block mr-05 mb-05 float-left" v-if="this.data.resource_file_type === 'docx'">
                                <img src="/images/icons/doc.png" width="25px" />
                            </div>
                            <div class="d-inline-block mr-05 mb-05 float-left" v-if="this.data.resource_file_type === 'txt'">
                                <img src="/images/icons/doc.png" width="25px" />
                            </div>
                            <div class="d-inline-block mr-05 mb-05 float-left" v-if="this.data.resource_file_type === 'pdf'">
                                <img src="/images/icons/pdf.png" width="25px" />
                            </div>
                            <div class="d-inline-block mr-05 mb-05 float-left" v-if="this.data.resource_file_type === 'xls'">
                                <img src="/images/icons/xls.png" width="25px" />
                            </div>
                            <div class="d-inline-block mr-05 mb-05 float-left" v-if="this.data.resource_file_type === 'xlsx'">
                                <img src="/images/icons/xls.png" width="25px" />
                            </div>
                            <div class="d-inline-block mr-05 mb-05 float-left" v-if="this.data.resource_file_type === 'ppt'">
                                <img src="/images/icons/ppt.png" width="25px" />
                            </div>
                            <div class="d-inline-block mr-05 mb-05 float-left" v-if="this.data.resource_file_type === 'pptx'">
                                <img src="/images/icons/ppt.png" width="25px" />
                            </div>
                            <div class="d-inline-block mr-05 mb-05 float-left" v-if="this.data.resource_file_type === 'lms'">
                                <img src="/images/icons/lms.png" width="25px" />
                            </div>
                        </div>

                        <span class="h3">
                            <a :href="'/member/' + this.data.type + '/'+this.data.slug" style="color: inherit">{{ this.data.title }}</a>
                        </span>

                        <div class="mt-05  mb-05" v-if="average_rating !== null && average_rating !== 0">
                            <span :class="'rating-static rating-'+average_rating * 10">  </span>
                        </div>
                    </div>
                </div>

                <div class="pt-05 pb-05">
                    <div v-if="collapse_body" class="breakAll image-resize " v-linkified v-html="data.resource_description"></div>
                    <div v-else :class="class_body+' image-resize '" ref="textBox" v-linkified v-html="data.resource_description"></div>

                    <div v-if="enable_read_more">
                        <button v-if="collapse_body" type="button" class="btn btn-link font-small-3 mt-05 mb-05 p-0" @click.prevent="collapse_body = false">
                            {{ __('globals.read_less') }} <i class="fa fa-chevron-up"></i>
                        </button>
                        <button v-else type="button" class="btn btn-link font-small-3 mt-05 mb-05 p-0" @click.prevent="collapse_body = true">
                            {{ __('globals.read_more') }} <i class="fa fa-chevron-down"></i>
                        </button>
                    </div>
                </div>

                <div class="pb-1 pt-05 text-bold-700" v-if="this.data.can_be_downloaded === false && data.price !== null">
                    <span class="font-small-2 pt-1"> {{ __('globals.form.paragraph.your_price') }} {{ this.data.price | currency(site_tax) }} {{ __('globals.all_inclusive') }}</span>
                </div>
                <div v-if="user.confirmed" class="border-top pt-05">

                    <span v-if="data.price === null && this.lowest_plan_price !== null && this.data.can_be_downloaded === false" class="font-small-2">
                        {{ this.lowest_plan_price | currency(site_tax)}} {{ __('globals.for') }} {{ this.lowest_plan_name }} {{ __('plan.upgrade_plan_message') }}
                    </span>
                    <span  v-else-if="this.data.can_be_downloaded && this.data.resource_file_type !== 'lms'">
                        <div v-if="this.data.external_url !== null">
                            <a :href="'/member/' + this.data.type + '/'+this.data.slug+'/access-external-link'" class="download-button btn btn-primary waves-effect waves-light form-control" target="_blank">
                                <i class="fa fa-link"></i> {{ __('resource.form.buttons.access_external_resource') }}
                            </a>
                        </div>
                        <div v-else>
                            <a :href="'/member/' + this.data.type + '/'+this.data.slug+'/download-file'"
                                class="download-button btn btn-primary waves-effect waves-light form-control">
                                <i class="fa fa-download"></i> {{ __('resource.form.buttons.download') }}
                            </a>
                        </div>
                    </span>
                    <span class="border-bottom" v-else-if="this.data.price > 0 && this.data.can_be_downloaded === false">
                        <a :href="'/member/' + this.data.type + '/'+this.data.slug+'/add_to_cart'" class="download-button btn btn-primary waves-effect waves-light form-control">
                            <i class="fa fa-download"></i> {{ __('resource.form.buttons.buy') }}
                        </a>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['data'],
    name: "NewsfeedSharedResource",
    data() {
        return {
            site_tax: window.App.site_tax,
            user: window.App.user,
            prices_array: [],
            average_rating: null,
            lowest_plan_name: null,
            lowest_plan_price: null,
            price_setting: window.App.price_setting,
            enable_read_more: false,
            collapse_body: false,
            class_body: 'breakAll',
        }
    },
    methods: {
         init() {
            let ratings_array = [];
            if(this.data.ratings.length > 0){
                this.data.ratings.forEach(rating => {
                    ratings_array.push(rating.rating);
                })
            }
            let sum = ratings_array.reduce((a, b) => a + b, 0);
            this.average_rating = Math.ceil((sum / ratings_array.length) || 0);
        },
        resourcePrice(plan_prices) {
            if (this.price_setting === 'plan-price' && plan_prices.length > 0) {
                plan_prices.forEach(plan => {
                    if(plan.price !== null) {
                        // This is used to set the price based on lowest plan price if the user is not on a plan where price is available
                        if (this.lowest_plan_price === null) {
                            this.lowest_plan_price = plan.price;
                            this.lowest_plan_name = plan.plan.title;
                        } else if (plan.price < this.lowest_plan_price) {
                            this.lowest_plan_price = plan.price;
                            this.lowest_plan_name = plan.plan.title;
                        }
                    }
                })
            }
        }
    },
    beforeMount() {
        this.init();
        this.resourcePrice(this.data.resource_plan_prices);
    },
    mounted() {
        if (this.$refs.textBox.clientHeight >= 300) {
            this.enable_read_more = true;
            this.class_body = 'breakAll overflow-fade';
        }
    },
}
</script>

<style scoped>

</style>
