<template>
        <div class="card">
            <div class="d-flex flex-row align-items-center">
                <div class="d-flex justify-content-start">
                    <div class="p-1">
                        <img class="round"
                             :src="member.avatar !== null ? member.avatar.url :  '/images/avatar.png'"
                             alt="avatar" height="30" width="30"/>
                    </div>

                    <div class="pl-0 pt-1 pr-1 pb-1">
                        <a :href="'/member/profiles/'+member.username" style="color:inherit;" class="text-bold-600">
                            {{ member.first_name }} {{ member.last_name }}
                        </a>

                        <span v-for="badge in member.badges">
                        <img :src="badge.image" style="margin-top: -3px;  height: 15px;" data-toggle="tooltip"
                             data-placement="bottom"
                             :title="badge.name" alt="badge image"/>
                    </span>

                        <img :src="member.user_badge_image.url" style="margin-top: -3px;  height: 15px;"
                             data-toggle="tooltip"
                             data-placement="bottom"
                             :title="member.user_badge_name"
                             v-if="member.user_badge_name !== null && member.user_badge_icon !== null"
                             alt="badge image"/>

                        <div class="font-small-2 text-muted">{{ member.position }}
                            {{ member.organisation !== null ? 'at ' + member.organisation : '' }}
                        </div>
                    </div>
                </div>

                <div class="d-flex p-1 ml-auto">
                    <button class="btn waves-effect waves-light btn-sm"
                            :class="{'btn-success': !isAssignedToCompany, 'btn-danger': isAssignedToCompany}"
                            @click.prevent="toggleAssignment">
                        {{ assignmentButtonText }}
                    </button>
                    <div class="ml-1" v-if="this.isPartOfOrganisation === 0">
                        <button v-if="isAdminButtonVisible" class="btn waves-effect waves-light btn-sm"
                                :class="{'btn-success': !isAdminOfCompany, 'btn-danger': isAdminOfCompany}"
                                @click.prevent="toggleCompanyAdminAssignment">
                            {{ assignmentAdminButtonText }}
                        </button>
                        <button v-if="isLocalCompanyAdmin" class="btn btn-danger btn-sm" @click.prevent="removeAdminOfCompany">
                            Remove Admin
                        </button>
                    </div>

                </div>
            </div>
        </div>
</template>

<script>
export default {
    name: "CompanyUserDirectoryCard",
    props: {
        member: Object,
        index: Number,
        companyId: {
            type: Number,
            required: true,
            validator: function (value) {
                return Number.isInteger(value);
            }
        },
        planId: {
            type: Number,
            required: false,
            validator: function (value) {
                return value === null || Number.isInteger(value);
            }
        },
        partOfOrganisation: {
            type: Number,
            required: true,
        },
        companyAdmin: {
            type: Number,
            required: false,
            validator: function (value) {
                return value === null || Number.isInteger(value);
            }
        }
    },
    components: {},
    data() {
        return {
            user_id: window.App.user.id,
            localCompanyAdmin: this.companyAdmin,
            isPartOfOrganisation: this.partOfOrganisation // Initialize with the passed prop
        }
    },
    mounted() {
        // Listen for the custom event dispatched by the checkbox change
        window.addEventListener('part-of-organisation-changed', this.handlePartOfOrganisationChange);
    },
    beforeDestroy() {
        // Clean up the event listener when the component is destroyed
        window.removeEventListener('part-of-organisation-changed', this.handlePartOfOrganisationChange);
    },
    watch: {
        companyAdmin(newVal) {
            this.localCompanyAdmin = newVal;
        }
    },
    computed: {
        isAssignedToCompany() {
            return this.member.companies_id === this.companyId;
        },
        assignmentButtonText() {
            return this.isAssignedToCompany ? "Remove" : "Assign";
        },
        assignmentAdminButtonText() {
            return this.isAssignedToCompany && this.companyAdmin === this.member.id ? "Remove Admin" : "Make Admin";
        },
        isAdminOfCompany() {
            return this.member.id === this.companyAdmin
        },
        isLocalCompanyAdmin() {
            return this.localCompanyAdmin === this.member.id;
        },
        isAdminButtonVisible() {
            return !this.isLocalCompanyAdmin && (this.companyAdmin === 0 || this.companyAdmin === this.member.id);
        }
    },
    methods: {
        handlePartOfOrganisationChange(event) {
            // Update the local state based on the event's detail
            this.isPartOfOrganisation = event.detail.isPartOfOrganisation;
        },
        toggleAssignment() {
            if (this.isAssignedToCompany) {
                this.removeUserFromCompany();
            } else {
                this.addUserToCompany();
            }
        },
        toggleCompanyAdminAssignment() {
            if (this.isAdminOfCompany) {
                this.removeAdminOfCompany();
            } else {
                this.addAdminToCompany();
            }
        },
        addAdminToCompany() {
            axios
                .put(`/admin/company-management/${this.member.username}/add-admin-to-company/${this.companyId}`)
                .then(response => {
                    this.flashResponse(response.data.message, 'success');
                    this.$emit('updateCompanyAdmin', this.member.id);

                    window.dispatchEvent(new CustomEvent('admin-assigned', {
                        detail: {
                            userId: this.member.id,
                            companyId: this.companyId
                        }
                    }));
                })
                .catch((error) => {
                    this.flashResponse('An error occurred', 'danger')
                });
        },
        addUserToCompany() {
            axios
                .put(`/admin/company-management/${this.member.username}/add-user-to-company/${this.companyId}`)
                .then(response => {
                    this.flashResponse(response.data.message, 'success');
                    this.member.companies_id = this.companyId
                })
                .catch((error) => {
                    this.flashResponse('An error occurred', 'danger')
                });
        },
        removeAdminOfCompany() {
            // Show confirmation dialog to the user
            const confirmation = window.confirm('Removing the admin from the company will cancel their subscription. Do you wish to proceed?');

            // Proceed only if the user confirmed
            if (confirmation) {
                axios
                    .put(`/admin/company-management/${this.member.username}/remove-admin-from-company/${this.companyId}`)
                    .then(response => {
                        this.flashResponse(response.data.message, 'success');
                        this.$emit('updateCompanyAdmin', 0);

                        window.dispatchEvent(new CustomEvent('admin-removed', {
                            detail: {
                                userId: this.member.id,
                                companyId: this.companyId
                            }
                        }));
                    })
                    .catch((error) => {
                        this.flashResponse('An error occurred', 'danger');
                    });
            }
        },

        removeUserFromCompany() {
            const confirmation = window.confirm('Are you sure?');

            // Proceed only if the user confirmed
            if (confirmation) {
                axios
                    .put(`/admin/company-management/${this.member.username}/remove-user-from-company/${this.companyId}`)
                    .then(response => {
                        this.flashResponse(response.data.message, 'success')
                        this.member.companies_id = null;
                    })
                    .catch((error) => {
                        this.flashResponse('An error occurred', 'danger')
                    });
            }
        },
        flashResponse(message, status) {
            let flash_data = {
                'message': message,
                'level': status
            }
            flash(flash_data);
        }
    }
}

</script>

<style scoped>

</style>
