<template>
        <div class="card">
            <div class="d-flex flex-row align-items-center">
                <div class="d-flex justify-content-start">
                    <div class="p-1">
                        <img class="round"
                             :src="member.avatar !== null ? member.avatar.url :  '/images/avatar.png'"
                             alt="avatar" height="30" width="30"/>
                    </div>

                    <div class="pl-0 pt-1 pr-1 pb-1">
                        <a :href="'/member/profiles/'+member.username" style="color:inherit;" class="text-bold-600">
                            {{ member.first_name }} {{ member.last_name }}
                        </a>

                        <span v-for="badge in member.badges">
                        <img :src="badge.image" style="margin-top: -3px;  height: 15px;" data-toggle="tooltip"
                             data-placement="bottom"
                             :title="badge.name" alt="badge image"/>
                    </span>

                        <img :src="member.user_badge_image.url" style="margin-top: -3px;  height: 15px;"
                             data-toggle="tooltip"
                             data-placement="bottom"
                             :title="member.user_badge_name"
                             v-if="member.user_badge_name !== null && member.user_badge_icon !== null"
                             alt="badge image"/>

                        <div class="font-small-2 text-muted">{{ member.position }}
                            {{ member.organisation !== null ? 'at ' + member.organisation : '' }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
export default {
    name: "CompanyUserListCard",
    props: {
        member: Object,
        index: Number,
        companyId: {
            type: Number,
            required: true,
            validator: function (value) {
                return Number.isInteger(value);
            }
        }
    },
    components: {},
    data() {
        return {
            user_id: window.App.user.id,
        }
    }
}

</script>

<style scoped>

</style>
