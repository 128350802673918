
import vSelect from 'vue-select' // multi select vue component
import VueApexCharts from 'vue-apexcharts'

require('./bootstrap');

// TinyMCE - if we want to move tinyMCE into app.js
// window.tinymce = require('../../node_modules/tinymce')
// require('../../node_modules/tinymce/models/dom/model.min.js')
// require('../../node_modules/tinymce/plugins/lists/plugin.min.js')
// require('../../node_modules/tinymce/icons/default/index.js')
// require('../../node_modules/tinymce/themes/silver/theme.min.js')
// require('../../node_modules/tinymce/plugins/code/plugin.min.js')
// require('../../node_modules/tinymce/plugins/image/plugin.min.js')
// require('../../node_modules/tinymce/plugins/link/plugin.min.js')
// require('../../node_modules/tinymce/plugins/media/plugin.min.js')
// require('../../node_modules/tinymce/plugins/table/plugin.min.js')
// require('../../node_modules/tinymce/plugins/wordcount/plugin.min.js')

window.moment = require('moment-timezone');

window.Vue = require('vue').default;
window.TomSelect = require('tom-select')
Vue.use(require('vue-chat-scroll'))
Vue.use(require('vue-resource'))
Vue.use(VueApexCharts)
Vue.mixin(require('./trans'))

Vue.config.ignoredElements = ['video-player'];

// Apex
Vue.component('apexchart', VueApexCharts)

//admin components

// -- Email
Vue.component('email-marketing', require('./components/admin/EmailMarketing.vue').default);

Vue.component('email-marketing-index', require('./components/admin/EmailMarketingIndex.vue').default);
Vue.component('email-marketing-create', require('./components/admin/EmailMarketingCreate.vue').default);
Vue.component('email-marketing-behavioural-trigger-index', require('./components/admin/EmailMarketingBehaviouralTriggerIndex.vue').default);
Vue.component('email-marketing-behavioural-trigger-create', require('./components/admin/EmailMarketingBehaviouralTriggerCreate.vue').default);

// Vue.component('email-stats', require('./components/admin/EmailStats.vue').default);


Vue.component('supplier-types', require('./components/admin/SupplierTypes.vue').default);
Vue.component('generate-invoice', require('./components/admin/GenerateInvoice.vue').default);
Vue.component('video-owner-selector', require('./components/admin/VideoOwnerSelector.vue').default);
Vue.component('language-manager', require('./components/admin/LanguageManager.vue').default);
Vue.component('global-languages', require('./components/admin/GlobalLanguages.vue').default);
Vue.component('crud-languages', require('./components/admin/CrudLanguages.vue').default);
Vue.component('new-crud-language', require('./components/admin/NewCrudLanguage.vue').default);

Vue.component('flash', require('./components/Flash.vue').default);
Vue.component('comments', require('./components/comment/Comments.vue').default);
Vue.component('like', require('./components/Like.vue').default);
Vue.component('likes', require('./components/newsfeed/components/Likes.vue').default);
Vue.component('chat', require('./components/Chat.vue').default);
Vue.component('messages', require('./components/Messages.vue').default);
Vue.component('message', require('./components/Message.vue').default);
Vue.component('group-message', require('./components/GroupMessage.vue').default);
Vue.component('user-notifications', require('./components/UserNotifications.vue').default);
Vue.component('post-view', require('./pages/Post.vue').default);
Vue.component('follow', require('./components/Follow.vue').default);
Vue.component('join-service', require('./components/JoinService.vue').default);
Vue.component('join-group', require('./components/JoinGroup.vue').default);
Vue.component('join-supplier', require('./components/JoinSupplier.vue').default);
Vue.component('infinite-scroll', require('./components/InfiniteScroll.vue').default);

Vue.component('new-infinite-scroll', require('./components/NewInfiniteScroll.vue').default);

Vue.component('question-infinite-scroll', require('./components/infinite-scroll/QuestionInfiniteScroll.vue').default);

Vue.component('groups-infinite-scroll', require('./components/infinite-scroll/GroupInfiniteScroll.vue').default);

Vue.component('products-infinite-scroll', require('./components/infinite-scroll/ProductInfiniteScroll.vue').default);
Vue.component('products-infinite-scroll-card', require('./components/infinite-scroll/cards/Product.vue').default);

Vue.component('jobs-infinite-scroll', require('./components/infinite-scroll/JobInfiniteScroll.vue').default);
Vue.component('jobs-infinite-scroll-card', require('./components/infinite-scroll/cards/Job.vue').default);

Vue.component('resources-infinite-scroll', require('./components/infinite-scroll/ResourceInfiniteScroll.vue').default);
Vue.component('resources-infinite-scroll-card', require('./components/infinite-scroll/cards/Resource.vue').default);

Vue.component('lms-infinite-scroll', require('./components/infinite-scroll/LMSInfiniteScroll.vue').default);

Vue.component('videos-infinite-scroll', require('./components/infinite-scroll/VideoInfiniteScroll.vue').default);
Vue.component('videos-infinite-scroll-card', require('./components/infinite-scroll/cards/Video.vue').default);

Vue.component('events-infinite-scroll', require('./components/infinite-scroll/EventInfiniteScroll.vue').default);
Vue.component('events-infinite-scroll-card', require('./components/infinite-scroll/cards/Event.vue').default);

Vue.component('suppliers-infinite-scroll', require('./components/infinite-scroll/SupplierInfiniteScroll.vue').default);
Vue.component('suppliers-infinite-scroll-card', require('./components/infinite-scroll/cards/Supplier.vue').default);

Vue.component('activity-infinite-scroll', require('./components/infinite-scroll/ActivityInfiniteScroll.vue').default);

Vue.component('contacts-infinite-scroll', require('./components/infinite-scroll/ContactInfiniteScroll.vue').default);

// Guest Infinite Scrolls
Vue.component('guest-events-infinite-scroll', require('./components/guest/infinite-scroll/GuestEventInfiniteScroll.vue').default);
Vue.component('guest-questions-infinite-scroll', require('./components/guest/infinite-scroll/GuestQuestionInfiniteScroll.vue').default);
Vue.component('guest-resources-infinite-scroll', require('./components/guest/infinite-scroll/GuestResourceInfiniteScroll.vue').default);

Vue.component('ticket', require('./components/tickets/Ticket_create.vue').default);
Vue.component('ticket-list', require('./components/tickets/Ticket_list.vue').default);
Vue.component('cart', require('./components/purchases/Cart.vue').default);
Vue.component('advertisement', require('./components/Advertisement.vue').default);
Vue.component('search', require('./components/GlobalSearch.vue').default);
Vue.component('close-help-box', require('./components/CloseHelpBox.vue').default);

Vue.component('post', require('./components/post/Post.vue').default);
Vue.component('videopost', require('./components/post/Video.vue').default);
Vue.component('resourcepost', require('./components/post/Resource.vue').default);

// new view file for featured contents
Vue.component('newsfeed', require('./components/newsfeed/member/Newsfeed.vue').default);

//More info posts
Vue.component('event-info', require('./components/more-info-post/Event.vue').default);
Vue.component('product-info', require('./components/more-info-post/Product.vue').default);
Vue.component('job-info', require('./components/more-info-post/Job.vue').default);
Vue.component('post-info', require('./components/more-info-post/Post.vue').default);
Vue.component('resource-info', require('./components/more-info-post/Resource.vue').default);
Vue.component('video-info', require('./components/more-info-post/Video.vue').default);
Vue.component('zoom-info', require('./components/more-info-post/Zoom.vue').default);
Vue.component('zoom-webinar-info', require('./components/more-info-post/ZoomWebinar.vue').default);

Vue.component('ratings', require('./components/rating/Ratings.vue').default);

Vue.component('invite', require('./components/invites/Invite.vue').default);
Vue.component('accept-reject-invite', require('./components/invites/AcceptRejectInvite.vue').default);
Vue.component('admin', require('./components/ServiceAdmin.vue').default);
Vue.component('manage-followers', require('./components/ManageFollowers.vue').default);
Vue.component('country-state-picker', require('./components/CountryStatePicker.vue').default);

// Guest Plans
Vue.component('register-plans', require('./components/guest/register/RegisterPlans.vue').default);

//Guest Pages
Vue.component('guest-product-info', require('./components/guest/Product.vue').default);
Vue.component('guest-event-info', require('./components/guest/Event.vue').default);
Vue.component('guest-zoom-info', require('./components/guest/Zoom.vue').default);
Vue.component('guest-zoom-webinar-info', require('./components/guest/ZoomWebinar.vue').default);
Vue.component('guest-job-info', require('./components/guest/Job.vue').default);
Vue.component('guest-resource-info', require('./components/guest/Resource.vue').default);
Vue.component('guest-video-info', require('./components/guest/Video.vue').default);
Vue.component('guest-post-info', require('./components/guest/Post.vue').default);
Vue.component('guest-group-info', require('./components/guest/Group.vue').default);
Vue.component('guest-supplier-info', require('./components/guest/Supplier.vue').default);
Vue.component('guest-question-info', require('./components/guest/Question.vue').default);

// Guest Newsfeed Vue components
Vue.component('guest-newsfeed', require('./components/guest/Newsfeed.vue').default);
Vue.component('guest-post-owner-info', require('./components/guest/PostOwnerInfo.vue').default);
Vue.component('guest-service-owner-info', require('./components/guest/ServiceOwnerInfo.vue').default);
Vue.component('guest-social-share', require('./components/guest/SocialShare.vue').default);
Vue.component('guest-newsfeed-event', require('./components/guest/newsfeed/Event.vue').default);
Vue.component('guest-newsfeed-job', require('./components/guest/newsfeed/Job.vue').default);
Vue.component('guest-newsfeed-post', require('./components/guest/newsfeed/Post.vue').default);
Vue.component('guest-newsfeed-product', require('./components/guest/newsfeed/Product.vue').default);
Vue.component('guest-newsfeed-resource', require('./components/guest/newsfeed/Resource.vue').default);
Vue.component('guest-newsfeed-video', require('./components/guest/newsfeed/Video.vue').default);
Vue.component('guest-newsfeed-zoom', require('./components/guest/newsfeed/Zoom.vue').default);
Vue.component('guest-newsfeed-zoom-webinar', require('./components/guest/newsfeed/ZoomWebinar.vue').default);
Vue.component('guest-newsfeed-question', require('./components/guest/newsfeed/Question.vue').default);

// Invite users to answer question
Vue.component('invite-users', require('./components/newsfeed/components/InviteUsers.vue').default);

//scheduled posts
Vue.component('scheduled-post', require('./components/post/ScheduledPost.vue').default);

//member search
Vue.component('member-search', require('./components/MemberSearch.vue').default);
Vue.component('company-user-directory-card', require('./components/CompanyUserDirectoryCard.vue').default);
Vue.component('company-user-search', require('./components/CompanyUserSearch.vue').default);
Vue.component('company-user-list-card', require('./components/CompanyUserListCard.vue').default);
Vue.component('company-user-list', require('./components/CompanyUserList.vue').default);
Vue.component('company-directory-card', require('./components/CompanyDirectoryCard.vue').default);
Vue.component('company-list', require('./components/CompanyList.vue').default);
Vue.component('organisation-company-list', require('./components/OrganisationCompanyList.vue').default);
Vue.component('organisation-company-directory-card', require('./components/OrganisationCompanyDirectoryCard.vue').default);
Vue.component('organisation-admin-list', require('./components/OrganisationAdminList.vue').default);
Vue.component('organisation-admin-directory-card', require('./components/OrganisationAdminDirectoryCard.vue').default);

Vue.component('supplier-types-selector', require('./components/SupplierTypesSelector.vue').default);

Vue.component('pending-group-joins', require('./components/PendingJoins.vue').default);
Vue.component('make-admin', require('./components/MakeAdmin.vue').default);
Vue.component('followers', require('./components/Followers.vue').default);
Vue.component('pending-followers', require('./components/PendingFollowers.vue').default);
Vue.component('pending-joins', require('./components/PendingJoins.vue').default);
Vue.component('media-manager', require('./components/MediaManager.vue').default);
Vue.component('social-share', require('./components/social/SocialShare.vue').default);

//Newsfeed Cards
Vue.component('newsfeed-webinar', require('./components/post/newsfeed/ZoomWebinar.vue').default);
Vue.component('newsfeed-meeting', require('./components/post/newsfeed/Zoom.vue').default);
Vue.component('newsfeed-event', require('./components/post/newsfeed/Event.vue').default);
Vue.component('newsfeed-post', require('./components/post/newsfeed/Post.vue').default);
Vue.component('newsfeed-product', require('./components/post/newsfeed/Product.vue').default);
Vue.component('newsfeed-job', require('./components/post/newsfeed/Job.vue').default);
Vue.component('newsfeed-video', require('./components/post/newsfeed/Video.vue').default);
Vue.component('newsfeed-resource', require('./components/post/newsfeed/Resource.vue').default);

//Newsfeed Share Cards
Vue.component('newsfeed-share-webinar', require('./components/post/share/ZoomWebinar.vue').default);
Vue.component('newsfeed-share-meeting', require('./components/post/share/Zoom.vue').default);
Vue.component('newsfeed-share-event', require('./components/post/share/Event.vue').default);
Vue.component('newsfeed-share-post', require('./components/post/share/Post.vue').default);
Vue.component('newsfeed-share-product', require('./components/post/share/Product.vue').default);
Vue.component('newsfeed-share-job', require('./components/post/share/Job.vue').default);
Vue.component('newsfeed-share-video', require('./components/post/share/Video.vue').default);
Vue.component('newsfeed-share-resource', require('./components/post/share/Resource.vue').default);

Vue.component('newsfeed-owner-info', require('./components/newsfeed/components/NewsfeedOwnerInfo.vue').default);

// Member Newsfeed View
Vue.component('member-shared-newsfeed', require('./components/newsfeed/member/SharedNewsfeed.vue').default);
Vue.component('member-newsfeed', require('./components/newsfeed/member/Newsfeed.vue').default);

// Shared Newsfeed Components
Vue.component('newsfeed-shared-event', require('./components/newsfeed/member/shared/Event.vue').default);
Vue.component('newsfeed-shared-job', require('./components/newsfeed/member/shared/Job.vue').default);
Vue.component('newsfeed-shared-post', require('./components/newsfeed/member/shared/Post.vue').default);
Vue.component('newsfeed-shared-product', require('./components/newsfeed/member/shared/Product.vue').default);
Vue.component('newsfeed-shared-resource', require('./components/newsfeed/member/shared/Resource.vue').default);
Vue.component('newsfeed-shared-video', require('./components/newsfeed/member/shared/Video.vue').default);
Vue.component('newsfeed-shared-zoom', require('./components/newsfeed/member/shared/Zoom.vue').default);
Vue.component('newsfeed-shared-zoom-webinar', require('./components/newsfeed/member/shared/ZoomWebinar.vue').default);

// Deleted Share Items
Vue.component('newsfeed-deleted-resource', require('./components/post/deleted/Resource').default)

// Question Components
Vue.component('make-question', require('./components/Question').default);
Vue.component('v-select', vSelect);

// Stat Components
Vue.component('stats-downloads', require('./components/admin/stats/Downloads.vue').default);
Vue.component('stats-views', require('./components/admin/stats/Views.vue').default);
Vue.component('stats-clicks', require('./components/admin/stats/Clicks.vue').default);

//Admin Reports Page
Vue.component('reports-component', require('./components/admin/ReportsComponent.vue').default);

// Event attendee
Vue.component('event-attendee', require('./components/EventAttendee.vue').default)

// Group members and Supplier followers Infinite Scroll
Vue.component('service-members', require('./components/ServiceMembers.vue').default);

// Manage Subscription
Vue.component('manage-subscription', require('./components/plans/ManageSubscription.vue').default)

// Admin Stats
Vue.component('page-stats', require('./components/admin/stats/PageStats.vue').default);
Vue.component('register-stats', require('./components/admin/stats/RegisterStats.vue').default);
Vue.component('register-steps-stats', require('./components/admin/stats/RegisterStepsStats.vue').default);
Vue.component('user-engagement', require('./components/admin/stats/UserEngagement.vue').default);

// Admin Type Page
Vue.component('type-page', require('./components/admin/TypePage.vue').default);

// Admin Tag Page
Vue.component('tag-page', require('./components/admin/TagPage.vue').default);

Vue.filter('currency', function(value, site_tax) {
    return site_tax.currency_symbol + parseFloat(value / 100).toFixed(2);
});

Vue.filter('tax', function(price, site_tax){
    let exclusive = price / (+1 + +site_tax.rate);
    let tax = Math.abs(price - exclusive) / 100;
    return site_tax.currency_symbol + parseFloat(tax).toFixed(2);
})

Vue.filter('nl2br', function(str, is_xhtml){
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1'+ breakTag +'$2');
});

const app = new Vue({
    el: '#app',
    methods: {
        handleCustomEvent(event){
            // Used in guest register page to set the value of Plan id using the new Vue.js component
            let planElement = document.getElementById('planElement');
            planElement.value = event.value
        }
    }
});

