<template>
        <div class="card">
            <div class="d-flex flex-row align-items-center">
                <div class="d-flex justify-content-start">
                    <div class="p-1">

                    </div>

                    <div class="pl-0 pt-1 pr-1 pb-1">
                        <div style="color:inherit;" class="text-bold-600">
                            {{ company.name }}
                        </div>
                    </div>
                </div>

                <div class="p-1 ml-auto">
                    <button class="btn waves-effect waves-light btn-sm"
                            :class="{'btn-success': !isAssignedToOrganisation, 'btn-danger': isAssignedToOrganisation}"
                            @click.prevent="toggleAssignment">
                        {{ assignmentButtonText }}
                    </button>
                </div>
            </div>
        </div>
</template>

<script>
export default {
    name: "CompanyDirectoryCard",
    props: {
        company: Object,
        index: Number,
        organisationId: {
            type: Number,
            required: true,
            validator: function (value) {
                return Number.isInteger(value);
            }
        }
    },
    components: {},
    data() {
        return {
            user_id: window.App.user.id,
        }
    },
    computed: {
        isAssignedToOrganisation() {
            return this.company.organisation_id === this.organisationId;
        },
        assignmentButtonText() {
            return this.isAssignedToOrganisation ? "Remove" : "Assign";
        }
    },
    methods: {
        toggleAssignment() {
            if (this.isAssignedToOrganisation) {
                this.removeCompanyFromOrganisation();
            } else {
                this.addCompanyToOrganisation();
            }
        },
        addCompanyToOrganisation() {
            axios
                .put(`/admin/organisation-management/${this.company.id}/add-company-to-organisation/${this.organisationId}`)
                .then(response => {
                    this.flashResponse(response.data.message, 'success');
                    this.company.organisation_id = this.organisationId
                })
                .catch((error) => {
                    this.flashResponse('An error occurred', 'danger')
                });
        },
        removeCompanyFromOrganisation() {
            axios
                .put(`/admin/organisation-management/${this.company.id}/remove-company-from-organisation/${this.organisationId}`)
                .then(response => {
                    this.flashResponse(response.data.message, 'success')
                    this.company.organisation_id = null;
                })
                .catch((error) => {
                    this.flashResponse('An error occurred', 'danger')
                });
        },
        flashResponse(message, status) {
            let flash_data = {
                'message': message,
                'level': status
            }
            flash(flash_data);
        }
    }
}

</script>

<style scoped>

</style>
