<template>
    <div>
        <div class="d-flex mb-4">
            <div class=" flex-fill member-search-area">
                <div class="d-flex search-text-area">
                    <div class="input-container">
                        <input type="text"
                               @input="isTyping = true"
                               v-model="keyword" class="form-control input-search"
                               placeholder="Search for a company by their name...">
                        <button v-if="keyword" @click="clearInput" class="clear-button">x</button>
                    </div>
                </div>
                <div class="text-right">
                    <a v-if="!this.unassignedFilter" @click="filterByUnassigned()" class="small">View Unassigned Companies</a>
                    <a v-if="this.unassignedFilter" @click="filterByAssigned()" class="small">View Assigned Companies</a>
                </div>
            </div>
        </div>

        <div class="scrollable-panel">
            <div class="row match-height">
                <div class="col-lg-12 col-md-12 col-sm-12" v-for="(company, index) in companies" :key="index">
                    <company-directory-card
                        :company="company"
                        :organisationId="organisationId"
                        :index="index">
                    </company-directory-card>
                </div>
            </div>
            <infinite-loading spinner="spiral" :identifier="infiniteId" @distance="1" @infinite="infiniteHandler">
                <div slot="no-more"></div>
                <div slot="no-results"></div>
            </infinite-loading>
        </div>
    </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import CompanyDirectoryCard from './CompanyDirectoryCard.vue';
import _ from "lodash";

export default {
    name: "CompanyList",
    props: {
        organisationId: {
            type: Number,
            required: true,
            validator: function (value) {
                return Number.isInteger(value);
            }
        }
    },
    components: { InfiniteLoading, CompanyDirectoryCard },
    data() {
        return {
            companies: [],
            page: 1,
            keyword: '',
            infiniteId: +new Date(),
            isTyping: false,
            isLoading: false,
            unassignedFilter: false,
        }
    },
    watch: {
        keyword: _.debounce(function () {
            this.isTyping = false;
            this.page = 1;
            this.companies = [];
            this.infiniteId += 1;
        }, 1500),
        isTyping: function (value) {
            if (!value) {
                this.infiniteHandler();
            }
        }
    },
    methods: {
        filterByUnassigned() {
            this.page = 1;
            this.companies = [];
            this.infiniteId += 1
            this.unassignedFilter = true;
            this.infiniteHandler();
        },
        filterByAssigned() {
            this.page = 1;
            this.companies = [];
            this.infiniteId += 1
            this.unassignedFilter = false;
            this.infiniteHandler();
        },
        infiniteHandler($state) {
            this.loadCompanies($state);
        },
        loadCompanies($state) {
            let vm = this;
            let url = `/admin/organisation-management/assigned/${this.organisationId}?page=` + this.page;
            if (this.unassignedFilter) {
                url = "/admin/organisation-management/unassigned-companies?page=" + this.page;
            }
            axios
                .post(url, { keyword: this.keyword })
                .then(response => {
                    if (response.data.data.length > 0) {
                        vm.companies.push(...response.data.data);
                        $state.loaded();
                    } else {
                        $state.complete();
                    }
                })
                .catch(() => {
                    $state.complete();
                });
            vm.page++;
        },
        clearInput() {
            this.keyword = '';
            this.isTyping = false;
        },
    },
}
</script>

<style scoped>
.input-search {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.scrollable-panel {
    max-height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
}

.scrollable-panel::-webkit-scrollbar {
    width: 5px; /* Adjust the width of the scrollbar */
}

.scrollable-panel::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2); /* Color of the scrollbar thumb */
}

.input-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

.form-control {
    width: 100%;
    padding-right: 30px;
    box-sizing: border-box;
}

.clear-button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    padding: 0;
    line-height: 1;
    color: #aaa;
}

.clear-button:hover {
    color: #000;
}
</style>
