<template>
    <div>
        <div class="d-flex mb-2">
            <div class=" flex-fill member-search-area">
                <div class="d-flex search-text-area">
                    <div class="input-container">
                        <input type="text"
                               @input="isTyping = true"
                               v-model="keyword" class="form-control input-search"
                               placeholder="Search for a admin for the organisation">
                        <button v-if="keyword" @click="clearInput" class="clear-button">x</button>
                    </div>
                </div>

            </div>
        </div>
        <div class="pb-2">
            Users returned in the search belong to the companies assigned to this organisation
        </div>

        <div class="scrollable-panel">
            <div class="row match-height">
                <div class="col-lg-12 col-md-12 col-sm-12" v-for="(user, index) in users" :key="index">
                    <organisation-admin-directory-card
                        :organisationId="organisationId"
                        :planId="planId"
                        :user="user"
                        :organisationAdmin="organisationAdmin"
                        :index="index"
                        @updateOrganisationAdmin="handleUpdateOrganisationAdmin">

                    </organisation-admin-directory-card>
                </div>
            </div>
            <infinite-loading spinner="spiral" :identifier="infiniteId" @distance="1" @infinite="infiniteHandler">
                <div slot="no-more"></div>
                <div slot="no-results"></div>
            </infinite-loading>
        </div>
    </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import OrganisationAdminDirectoryCard from "./OrganisationAdminDirectoryCard.vue";

export default {
    name: "OrganisationAdminList",
    props: {
        organisationId: {
            type: Number,
            required: true,
            validator: function (value) {
                return Number.isInteger(value);
            }
        },
        organisationAdmin: {
            type: Number,
            required: false,
            validator: function (value) {
                return value === null || Number.isInteger(value);
            }
        },
        planId: {
            type: Number,
            required: true,
            validator: function (value) {
                return Number.isInteger(value);
            }
        }
    },
    components: {InfiniteLoading, OrganisationAdminDirectoryCard},
    data() {
        return {
            users: [],
            page: 1,
            keyword: '',
            infiniteId: +new Date(),
            isTyping: false,
            isLoading: false,
        }
    },
    methods: {
        infiniteHandler($state) {
            this.loadCompaniesUsers($state)
        },
        loadCompaniesUsers($state) {
            let vm = this;
            let url = `/admin/organisation-management/assign-admin/${this.organisationId}?page=` + this.page;
            axios
                .post(url, {keyword: this.keyword})
                .then(response => {
                    if (response.data.data.length > 0) {
                        $.each(response.data.data, function (key, value) {
                            vm.users.push(value);
                        });
                        $state.loaded();
                    } else {
                        $state.complete();
                    }
                });
            vm.page++;
        },
        clearInput() {
            this.keyword = '';
            this.isTyping = false;
        },
        handleUpdateOrganisationAdmin(memberId) {
            this.organisationAdmin = memberId;
        },
    },
    watch: {
        keyword: _.debounce(function () {
            this.isTyping = false;
            this.page = 1;
            this.users = [];
            this.infiniteId += 1;
        }, 1500),
        isTyping: function (value) {
            if (!value) {
                this.infiniteHandler();
            }
        }
    },

}
</script>

<style scoped>
.input-search {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.scrollable-panel {
    max-height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
}

.scrollable-panel::-webkit-scrollbar {
    width: 5px; /* Adjust the width of the scrollbar */
}

.scrollable-panel::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2); /* Color of the scrollbar thumb */
}

.input-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

.form-control {
    width: 100%;
    padding-right: 30px;
    box-sizing: border-box;
}

.clear-button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    padding: 0;
    line-height: 1;
    color: #aaa;
}

.clear-button:hover {
    color: #000;
}
</style>

