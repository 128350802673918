<template>
    <div class="row border-bottom p-05">
        <div class="col-md-10">
            <div class="text-md-start">
                <div>{{ company.name }}</div>
            </div>
        </div>
        <div class="col-md-2 text-md-right">
            <div class="float-right">
                <i class="fa fa-users"></i> {{ company.company_users_count}}

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "OrganisationCompanyDirectoryCard",
    props: {
        company: Object,
        index: Number,
        organisationId: {
            type: Number,
            required: true,
            validator: function (value) {
                return Number.isInteger(value);
            }
        }
    }
}

</script>

<style scoped>

</style>
